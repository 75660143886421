.container {
  --width: 66rem;
  --side-padding: 2rem;
  padding: 4rem 0 5rem 0;
  width: min(var(--width), 100% - var(--side-padding) * 2);
  margin-inline: auto;
}

.header {
  display: flex;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  height: 3.5rem;
  align-items: center;
  background-color: var(--clr-primary);
  z-index: 10;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

.logo {
  height: 4rem;
}

.nav {
  display: flex;
}

.nav li {
  list-style: none;
  margin-right: 1.25rem;
  padding: 0.5rem 0rem;
  cursor: pointer;
  font-size: 1.1rem !important;
  color: var(--clr-white);
}

.nav li a {
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 1.1rem !important;
}

.nav li:last-child {
  margin-right: 0;
  border-radius: 1rem;
}

main {
  padding-top: 3.5rem;
}

.hero {
  background-color: var(--clr-primary-lighter);
}

.hero-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44rem;
  color: var(--clr-white);
}

.hero-img {
  width: 28rem;
}

.hero-title {
  font-size: 4rem;
  margin-bottom: 1rem;
  font-weight: 700;
}

.hero-subtitle {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  font-weight: 300;
}

.hero-cta {
  border: none;
  padding: 0.6rem 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 1.2rem;
  margin: 0 auto;
  color: var(--clr-white);
  background-color: var(--clr-accent-2);
}

.hero-cta:hover {
  filter: brightness(1.1);
}

.section-title {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 2rem;
}

.about-paragraph-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.about-paragraph {
  color: var(--clr-gray);
  font-size: 1.1rem;
  margin-right: 3rem;
}

.aboutImage {
  width: 20rem;
  margin-left: 3rem;
}


.offer-paragraph-container {
  display: flex;
  align-items: center;
}

.offer-sub {
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.offer-text {
  margin-left: 3rem;
}

.offer-paragraph,
.work-paragraph {
  color: var(--clr-gray);
  margin-bottom: 1rem;
  font-size: 1.1rem;
}

.offerImage {
  width: 20rem;
  margin-right: 3rem;
  margin-bottom: 1.5rem;
}

.offer-list {
  position: relative;
  list-style: none;
  margin-bottom: 2rem;
}

.offer-list li {
  margin-left: 1.5rem;
  margin-bottom: 0.5rem;
  color: var(--clr-gray);
  font-size: 1.1rem;
}

.offer-list li::before {
  content: '▶';
  position: absolute;
  left: 0;
  margin-top: 3px;
  font-size: 0.9rem;
  color: var(--clr-primary-light);
}

.services-cta {
  border: none;
  padding: 0.6rem 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 1.2rem;
  color: var(--clr-white);
  background-color: var(--clr-accent-2);
  margin-right: 1rem;
}

.blue-cta {
  background-color: var(--clr-primary-light);
}

.portfolio-items {
  display: flex;
  justify-content: space-between;
}

.portfolio-item {
  display: flex;
  flex-direction: column;
  width: calc(50% - 1rem);
}

.portfolio-image-small {
  width: 100%;
  margin-bottom: 0.5rem;
}

.view-full-button {
  width: fit-content;
  padding: 0.2rem 0.4rem;
  background-color: transparent;
  border: none;
  color: var(--clr-primary-light);
  text-decoration: underline;
  cursor: pointer;
}

.contact {
  background-color: var(--clr-primary-lighter);
}

.contact-container {
  padding: 4rem 0;
}

.contact-title {
  color: var(--clr-white);
  text-align: center;
  margin-bottom: 0.5rem;
}

.contact-text {
  color: var(--clr-white);
  text-align: center;
}

.contact-text a {
  color: var(--clr-white);
}

.input-small-container {
  margin: 1.5rem auto 0 auto;
  width: min(80%, 32rem);
  display: flex;
  justify-content: space-between;
}

.input-small {
  background-color: var(--clr-primary);
  border: none;
  border-radius: 1rem;
  width: calc(50% - 0.5rem);
  padding: 0.5rem 0.75rem;
  color: var(--clr-white);
}

.input-large {
  resize: none;
  background-color: var(--clr-primary);
  border: none;
  border-radius: 1rem;
  width: min(80%, 32rem);
  padding: 0.5rem 0.75rem;
  color: var(--clr-white);
  display: block;
  margin: 1rem auto 0 auto;
}

.legal-contact-text {
  color: var(--clr-white);
  text-align: center;
  font-size: 0.9rem;
  margin: 1rem auto 0rem auto;
  max-width: 40rem;
}

.contact-send {
  border: none;
  padding: 0.6rem 2.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 1rem;
  display: block;
  margin: 1rem auto 0 auto;
  color: var(--clr-white);
  background-color: var(--clr-accent-2);
}

.message-confirmation {
  color: rgb(15, 176, 15);
  font-weight: 600;
  text-align: center;
  margin-top: 1rem;
}

.message-failed {
  color: #e83030;
  font-weight: 600;
  text-align: center;
  margin-top: 1rem;
}

footer {
  background-color: var(--clr-primary);
}

.footer-container {
  padding: 2rem 0;
}

.footer-text {
  color: var(--clr-white);
  text-align: center;
}


@media screen and (max-width: 65rem) {

  .hero-img,
  .aboutImage,
  .offerImage {
    width: 20rem;
  }

  .aboutImage {
    margin: 2rem 0 -4rem 0;
  }

  .offerImageContainer {
    margin: 2rem 0 -2rem 0;
    display: flex;
    flex-direction: column;
  }

  .work-cta {
    margin-bottom: 0.5rem;
  }

  .about-paragraph-container {
    flex-direction: column;
  }

  .offer-paragraph-container {
    flex-direction: column-reverse;
  }

  .offer-text {
    margin-left: 0;
  }
}

@media screen and (max-width: 50rem) {

  .hero-img,
  .aboutImage,
  .offerImage {
    display: none;
  }

  .nav {
    display: none;
  }

  .header-container {
    justify-content: center;
  }

  .hero-title {
    font-size: 2.7rem;
  }

  .offer-paragraph-container {
    align-items: flex-start;
  }
}