* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: inherit;
  font-weight: inherit;
}

:root {
  --clr-white: #FFF;
  --clr-black: #000;
  --clr-primary: #011638;
  --clr-primary-lighter: #072042;
  --clr-primary-light: #1e6ddc;
  --clr-gray: #42464d;
  --clr-gray-dark: #22262d;
  --clr-accent-1: #214E34;
  --clr-accent-2: #CC2936;
}